<template>
  <div class="team-service">
    <div class="w ww">
      <ul>
        <li v-for="(item) in productList" :key="item.owid">
          <div class="team-service-item">
            <a @click="goStudyList(item.owid)" @contextmenu="newWindowOpen($event,item.owid)"> <img :src="pictrueUrl+item.icon" alt="" /></a>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
          <div class="cate-name">{{item.cateName}}</div>
          <div class="cate-desc">{{item.cateDesc}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { productSort,studyList } from "./model";
import Pagination from "@/components/component/Pagination.vue";
import { IMGURL } from "@/utils/constant";

export default {
  data() {
    return {
      pictrueUrl: "",
      studyListData:'',
      productList: "",
      totalPage: 1,
      currentPage: 1,
    };
  },
  async created() {
    this.pictrueUrl = IMGURL;
    productSort({type:1}).then(res=>{
      res.forEach(item=>{
        this.productList= item.twoCate
      })
    })

  },
 
  methods: {
    //学习列表
    goStudyList(owid) {
      this.$router.push({path:'/studyList',query:{owid:owid}})
    },
    newWindowOpen(e,owid){
      e.path[1].href = ''
      e.path[1].href = e.path[1].href +'#/studyList?owid='+owid
    }
  },
  components: {
    Pagination,
  },
};
</script>
<style scoped>
.content {
  height: 0.4rem !important;
  margin-top: 0.1rem;
}
.content-item span{
  margin-top: -0.1rem;
  height: 0.15rem;
  line-height: 0.15rem;
}
.content-item p {
  margin-top: 0.05rem;
}
</style>
